import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-nonmembers',
  templateUrl: './nonmembers.component.html',
  styleUrls: ['./nonmembers.component.scss']
})
export class NonmembersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean = false;
  userId: any;
  claimCompanyName: any = [];
  selectedCompanyNames: any;
  filteredDropDownList: any;
  listOfCompanyNames: any;
  gridData: any = [];
  claimAllCompany: any = [];
  pagesize = 0;
  dataSource = new MatTableDataSource<any>(this.claimAllCompany);
  displayedColumns: string[] = ['eventDate', 'companyName', 'eventConsultationNum', 'action'];

  constructor(private globalData: GlobalDataService, private claimService: ClaimsService, private router: Router, private route: ActivatedRoute) {
    this.globalData.updateBackRoute('/wellness-event/corporate/financial');
  }

  ngOnInit(): void {
    this.getNonMembers();
  }

  async getClaimCompany() {
    this.isLoading = true;
    await this.claimService.GetAllCompanyName().then(data => {
      console.log("Ping - Claims compnay name", data);
      this.claimCompanyName = data;
      this.filteredDropDownList = data.filter(item1 =>
        this.gridData.some(item2 => item2.id === item1.id)
      )
      this.listOfCompanyNames = this.filteredDropDownList.sort((a, b) =>
        a.description.localeCompare(b.description));
      console.log("Ping - Claims compnay name--2", this.filteredDropDownList);
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  async getNonMembers() {
    this.isLoading = true;
    await this.claimService.getAllNonMembers().then(data => {
      console.log("Ping -Rejected Claims Data", data);
      this.pagesize = data.length;
      this.gridData = data;
      this.claimAllCompany = new MatTableDataSource<any>(data);
      // this.filteredDropDownList = data.filter(item1 =>
      //   this.claimCompanyName.some(item2 => item2.id === item1.id)
      // )
      // this.listOfCompanyNames = this.filteredDropDownList;
      // console.log("Ping - Claims compnay name--1", this.filteredDropDownList);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);
      this.getClaimCompany();
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  async goToInvoiceLanding(): Promise<void> {
    this.isLoading = true;

  }

  selectOption(event: any) {
    console.log(event.value);
    this.selectedCompanyNames = event.value;
  }

  searchClaim() {
    console.log("Process Claim");
    if (this.selectedCompanyNames.length > 0) {
      console.log("Selected Company", this.gridData);
      let filterGridData = this.gridData.filter(x => this.selectedCompanyNames.includes(x.id));
      this.pagesize = filterGridData.length;
      this.claimAllCompany = new MatTableDataSource<any>(filterGridData);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);

    }
    else {
      this.pagesize = this.gridData.length;
      this.claimAllCompany = new MatTableDataSource<any>(this.gridData);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);
      //alert("Please select a company");
    }
  }

  goToViewMembers(object: any) {
    console.log("View Members", object);
    this.router.navigate(['wellness-event/corporate/view-non-members'], { state: { object: object } });
  }

}
