import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimResponse } from 'src/app/shared/interfaces/claimParams';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';

@Component({
  selector: 'app-edit-member-dailog',
  templateUrl: './edit-member-dailog.component.html',
  styleUrls: ['./edit-member-dailog.component.scss']
})
export class EditMemberDailogComponent implements OnInit {
  editForm: any;
  userId: any;
  isLoading: boolean = false;
  schemeCodes: any = [];
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditMemberDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalData: GlobalDataService,
    private claimservicce: ClaimsService,
    private listService: ListService
  ) {
    this.globalData.userFullNameVal.subscribe(d => this.userId = d);
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      memberNumber: [this.data.memberNumber || null, Validators.required],
      dependantCode: [this.data.dependantCode || null, Validators.required],
      schemeCode: [this.data.mediscorPcncode?.slice(0, -2) || null, Validators.required],
      firstName: [this.data.patientFirstName || null, Validators.required],
      surname: [this.data.patientSurname || null, Validators.required],
      dob: [new Date(this.data.dob) || null, Validators.required],
    });

    this.listService.getById("081D737F-85B3-4FED-B892-8C2902D8A424").subscribe(data => {
      let schemes = [];
      data.listItems.forEach(x => {
        if (x.code.includes("MDS"))
          schemes.push(x);
      })
      this.schemeCodes = schemes;
      console.log("Ping - Scheme Codes", schemes);
    });
  }

  async onSave() {
    if (this.editForm.valid) {
      let data = this.editForm.getRawValue();
      let obj: ClaimResponse = {
        Id: this.data.id,
        MemberNumber: data.memberNumber,
        DependantCode: data.dependantCode,
        MediscorPcncode: data.schemeCode,
        Username: this.userId,
        firstName: data.firstName,
        surname: data.surname,
        dob: data.dob
      }
      this.isLoading = true;
      await this.claimservicce.UpdateIntoClaimResponse(obj).then(data => {
        console.log("Ping - Update Claim Response", data);
        this.dialogRef.close(this.editForm.value);
        this.isLoading = false;
      }).catch(error => {
        console.log("Error - Update Claim Response", error);
        this.dialogRef.close(this.editForm.value);
        this.isLoading = false;
      })
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}
