import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EapApprovalService } from 'src/app/shared/services/eap-approval.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-eap-landing',
  templateUrl: './eap-landing.component.html',
  styleUrls: ['./eap-landing.component.scss']
})
export class EapLandingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean = false;
  projectUsers: any[] = [];
  selectedUser: any;
  selectedUserName: string = '';
  approvalColumns: string[] = ['memberName', 'dateCreated', 'company', 'approvalStatus', 'action'];
  pagesize: number = 0;
  approvalRequestData: any = [];
  dataSource = new MatTableDataSource<any>(this.approvalRequestData);
  HCManager: boolean = false;
  constructor(private eapService: EapApprovalService, private globalData: GlobalDataService, private router: Router) {
    this.globalData.userRolesVal.subscribe(r => {
      this.HCManager = r.includes("HC Manager")
    })
  }

  async ngOnInit() {
    await this.addUser();
    if (this.HCManager) {
      await this.getAllApprovalData();
    } else {
      await this.getApprovalData();
    }
    await this.getEAPProjectUsers();
  }

  // Background processes
  async addUser() {
    this.isLoading = true;
    await this.eapService.addEapUser().then(data => {
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    })
  }

  async getApprovalData() {
    this.isLoading = true;
    await this.eapService.getApprovalData(this.eapService.keycloakId).then(data => {
      if (data?.length != 0) {
        this.pagesize = data?.length;
        let allData = [];
        data.forEach(element => {
          let approveData = {
            "MemberName": element?.memberName,
            "InsertedDate": new Date(element?.insertedDate).toISOString().split('T')[0],
            "Description": element?.customerName,
            "ApprovalStatus": element?.outcomePostReview == "Approved" ? "Approved" : element?.outcomePostReview == "Declined" ? "Declined" : "Open",//['Open', 'Approved', 'Declined'],
            "Data": element
          }
          allData.push(approveData)
        });

        this.approvalRequestData = allData;
        this.dataSource = new MatTableDataSource<any>(this.approvalRequestData);
        this.dataSource.paginator = this.paginator;
      }

      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    })
  }
  async getAllApprovalData() {
    this.isLoading = true;
    await this.eapService.getAllApprovalData().then(data => {
      if (data?.length != 0) {
        this.pagesize = data?.length;
        let allData = [];
        data.forEach(element => {
          let approveData = {
            "MemberName": element?.memberName,
            "InsertedDate": new Date(element?.insertedDate).toISOString().split('T')[0],
            "Description": element?.customerName,
            "ApprovalStatus": element?.outcomePostReview == "Approved" ? "Approved" : element?.outcomePostReview == "Declined" ? "Declined" : "Open",//['Open', 'Approved', 'Declined'],
            "Data": element
          }
          allData.push(approveData)
        });

        this.approvalRequestData = allData;
        this.dataSource = new MatTableDataSource<any>(this.approvalRequestData);
        this.dataSource.paginator = this.paginator;

      }

      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    })
  }
  async getEAPProjectUsers() {
    this.isLoading = true;
    await this.eapService.getEAPProjectUsers(this.eapService.keycloakId).then(data => {
      this.projectUsers = data;
      console.log("EAP Project Users", data);
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    })
  }

  onUserSelect(event: any) {
    this.selectedUserName = event.value.name;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  view(data: any) {
    console.log(data);
    this.router.navigate(['eap/additional'], { state: { object: data } });
  }

}
