import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { co } from '@fullcalendar/core/internal-common';
import { EapApprovalService, SynergyPayload } from 'src/app/shared/services/eap-approval.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-eap-additional',
  templateUrl: './eap-additional.component.html',
  styleUrls: ['./eap-additional.component.scss']
})
export class EapAdditionalComponent implements OnInit {

  isLoading: boolean = false;
  showCounter: boolean = false;
  memberDetails: any;
  selectedReason: string = '';
  rejectReasons: any = [
    {
      code: 'NM',
      name: 'No motivation'
    },
    {
      code: 'IM',
      name: 'Inappropriate motivation'
    },
    {
      code: 'IMN',
      name: 'Incomplete motivation'
    },
    {
      code: 'NBF',
      name: 'Not budgeted for'
    },
    {
      code: 'O',
      name: 'Other'
    }
  ];
  sessionCount: number = 1;
  eapForm: FormGroup;
  showOtherReason: boolean = false;


  constructor(private globalData: GlobalDataService, private eapservice: EapApprovalService, private fb: FormBuilder, private router: Router) {
    this.globalData.updateBackRoute('/eap/landing');
    if (history?.state) {
      this.memberDetails = history.state['object'];
      this.memberDetails = this.memberDetails.Data;
    }
    this.eapForm = this.fb.group({
      isApproved: [null, Validators.required],
      sessionCount: [1, [Validators.min(1), Validators.max(10)]],
      rejectReason: [null],
      otherReason: [null]
    });
    this.updateFormWithValue();
  }

  ngOnInit(): void {
  }

  openPdf() {
    this.isLoading = true;
    this.eapservice.getPDF(this.memberDetails.uniqueKey).then(data => {
      // const file = new Blob([data], { type: 'application/pdf' });
      // const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);


      const pdfBytes = atob(data)
        .split("")
        .map((char) => char.charCodeAt(0));

      // Create a new window and display the PDF
      const blob = new Blob([new Uint8Array(pdfBytes)], {
        type: "application/pdf",
      });
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl);
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
      console.log(err);
    })
  }

  incrementCount() {
    if (this.sessionCount < 10) {
      this.sessionCount++;
    }
  }

  decrementCount() {
    if (this.sessionCount > 1) {
      this.sessionCount--;
    }
  }

  setApprovalStatus(status: boolean) {
    this.eapForm.patchValue({
      isApproved: status
    });
    if (this.eapForm.get('isApproved').value) {
      this.eapForm.get('rejectReason').removeValidators(Validators.required);
      this.eapForm.get('rejectReason').clearValidators();
      this.eapForm.get('rejectReason').setValue(null);
      this.eapForm.get('otherReason').removeValidators(Validators.required);
      this.eapForm.get('otherReason').clearValidators();
      this.eapForm.get('otherReason').setValue(null);
    }


  }

  onReasonSelect(event: any) {
    this.selectedReason = event.value;
    this.showOtherReason = event.value === 'Other';
    if (event.value === 'Other') {
      this.eapForm.get('otherReason').addValidators(Validators.required);
    }
    else {
      this.eapForm.get('otherReason').removeValidators(Validators.required);
      this.eapForm.get('otherReason').clearValidators();
      this.eapForm.get('otherReason').setValue(null);
    }
    this.eapForm.updateValueAndValidity();
  }

  onSubmit() {
    let userName = '';
    this.globalData.userFullNameVal.subscribe(data => {
      userName = data;
    })
    if (this.eapForm.valid) {
      this.isLoading = true;
      let dataP = this.eapForm.getRawValue();
      let payload: SynergyPayload = {
        "UniqueKey": this.memberDetails.uniqueKey,
        "Approved": dataP.isApproved == true ? "Approved" : "Declined",
        "SessionsCount": this.sessionCount.toString(),
        "RejectReason": dataP.rejectReason,
        "OtherReason": dataP.otherReason,
        "userName": userName
      }
      this.eapservice.updateSynergyRequest(payload).then(data => {
        this.isLoading = false;
        console.log("EAP additional session approved", data);
        this.router.navigate(['/eap/landing']);
      }).catch(err => {
        console.log(err);
      })
    }
  }

  updateFormWithValue() {
    let reason = this.memberDetails.reasonForDeclined?.split(":");
    this.eapForm.patchValue({
      isApproved: this.memberDetails.outcomePostReview == "Approved" ? true : false,
      sessionCount: this.memberDetails.noOfSessions,
      rejectReason: reason != undefined ? reason[0] : '',
      otherReason: reason != undefined ? reason[1] : ''
    })
    if (reason != undefined) {
      this.showOtherReason = reason[0] == "Other" ? true : false;
    }
    if (this.memberDetails.outcomePostReview != 'Open') {
      this.sessionCount = this.memberDetails.noOfSessions;
      this.showCounter = false;
      this.eapForm.disable();
    }
    else {
      this.eapForm.reset();
      this.showCounter = true;
    }
  }

  reasonRequired() {
    this.eapForm.get('rejectReason').addValidators(Validators.required);
    this.eapForm.updateValueAndValidity();
    this.showOtherReason = false;
  }

}

