<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <div>
      <h2 class="mdc-typography--headline2 text--align-center">EAP approval requests</h2>
    </div>
  </section><br><br>
  <article class="grid">
    <!-- selection of HC Executives -->
    <!-- <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker
      style="width: 25%;">
      <mat-label>Allocated HC Executives</mat-label>
      <mat-select [(ngModel)]="selectedUser" (selectionChange)="onUserSelect($event)">
        <mat-option *ngFor="let user of projectUsers" [value]="user">
          {{user.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <br><br>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="approvalRequestData" class="w-full">

        <!-- Position Column -->
        <ng-container matColumnDef="memberName" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Member Name
          <th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.MemberName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dateCreated" width="40%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Date Created </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.InsertedDate}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="company" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Company </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>

        <ng-container matColumnDef="approvalStatus" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Status </th>
          <td class="text-left" mat-cell *matCellDef="let element">{{element.ApprovalStatus}}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="approvalStatus" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Approval Status </th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <mat-select [(value)]="element.ApprovalStatus[0]">
              <mat-option *ngFor="let option of element.ApprovalStatus" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </td>
        </ng-container> -->

        <!-- Button Column -->
        <ng-container matColumnDef="action" width="10%">
          <th class="text-left" mat-header-cell *matHeaderCellDef></th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <button mat-raised-button style="background-color: #ff403c; color: white;"
              (click)="view(element)">View</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="approvalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: approvalColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, pagesize]" showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </article>
</div>