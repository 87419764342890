import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { an } from '@fullcalendar/core/internal-common';
import { KeycloakService } from 'keycloak-angular';

import * as Enums from '../enums'
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class EapApprovalService {
  keycloakId: string;
  httpOptions: any = {};
  constructor(private http: HttpClient, private readonly keycloak: KeycloakService, private globalServ: GlobalDataService) { }

  addEapUser(): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    let keycloakInstance = this.keycloak.getKeycloakInstance().tokenParsed;
    this.keycloakId = keycloakInstance.sub;
    let payload: EAPUser = {
      "Id": null,
      "KeycloakId": keycloakInstance.sub,
      "Email": keycloakInstance.email,
      "Name": keycloakInstance.name,
      "EAPProjectDetailId": keycloakInstance?.Clinic[0],
    }
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.ADD_EAP_USER, payload, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }

  getApprovalData(keycloakId: string): Promise<any> {
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_EAP_SYNERGY_DATA + keycloakId, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }

  getAllApprovalData(): Promise<any> {
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_SYNERGY_REQUEST, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }
  getEAPProjectUsers(keycloakId: string): Promise<any> {
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_EAP_PROJECT_USERS + keycloakId, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }

  getPDF(memberID: any): Promise<any> {
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_EAP_PDF + memberID, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }

  updateSynergyRequest(payload: SynergyPayload): Promise<any> {
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.UPDATE_SYNERGY_REQUEST, payload, this.httpOptions).toPromise().then(data => {
      return data;
    }).catch(err => {
      return err;
    });
  }

}
export interface EAPUser {
  KeycloakId: string;
  Email: string;
  Name: string;
  EAPProjectDetailId: string;
  Id: string;
}

export interface SynergyPayload {
  Approved: string,
  SessionsCount: string,
  RejectReason: string,
  OtherReason: string,
  UniqueKey: string,
  userName: string,
}