<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <div>
      <h4 class="mdc-typography--headline2 text--align-center">EAP Additional Session</h4>
    </div>
  </section><br><br>
  <article class="grid">
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell grid-cell-style">
        <div class="row row-style">
          <div class="vertical-align-top">
            <div class="eapImage">&nbsp;</div>
            <div class="inline-block vertical-align-top">
              <h6 class="mdc-typography--headline5 headline-style">
                {{memberDetails.memberName}}
              </h6>
              <div class="center-text">
                <div class="inline-block line-height-1-5">{{memberDetails.memberId}}</div>
              </div>
              <div>
                <div class="inline-block line-height-1-5">&nbsp;</div>
              </div>
            </div>
          </div>
          <div
            class="cell--align-bottom align-text-bottom mdc-typography--body-1 vertical-align-bottom position-absolute right-0 bottom-0 text-align-right"
            id="consultationDate">
            <div class="line-height-1-5">EAP additional approval session</div>
            <div class="line-height-1-5">{{memberDetails.insertedDate | date: 'dd MMMM yyyy'}}</div>
            <div class="line-height-1-5" style="margin-bottom: 20px;">{{memberDetails.customerName}}
            </div>
            <!-- <div class="line-height-1-5" style="color: white;"></div> -->
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div class="body">
      <div class="pdf">
        <button class="searching" (click)="openPdf()">View Motivation</button>
      </div>
      <br><br>
      <div class="form-body">
        <form [formGroup]="eapForm" (ngSubmit)="onSubmit()">
          <label>Additional sessions approved?</label>
          <br>
          <div class="button-group">


            <button class="buttons" type="button" (click)="setApprovalStatus(true)"
              [class.selected]="eapForm.get('isApproved').value === true" [disabled]="!showCounter">Yes</button>
            <button class="buttons" type="button" (click)="setApprovalStatus(false)"
              [class.selected]="eapForm.get('isApproved').value === false" [disabled]="!showCounter"
              (click)="reasonRequired()">No</button>
          </div>
          <br><br>

          <div *ngIf="eapForm.get('isApproved').value === true">
            <label>Number of sessions</label>
            <div class="counter-container" *ngIf="showCounter">

              <button type="button" mat-icon-button (click)="decrementCount()">
                <mat-icon>-</mat-icon>
              </button>
              <span class="session-count">{{sessionCount}}</span>
              <button type="button" mat-icon-button (click)="incrementCount()">
                <mat-icon>+</mat-icon>
              </button>

            </div>
            <div *ngIf="!showCounter">
              {{sessionCount}}
            </div>
          </div>



          <div *ngIf="eapForm.get('isApproved').value === false" style="width: 50%;">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Reason for declining the request</mat-label>
              <mat-select formControlName="rejectReason" (selectionChange)="onReasonSelect($event)">
                <mat-option *ngFor="let reason of rejectReasons" [value]="reason.name">
                  {{reason.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="showOtherReason" class="whitneyFontClass motion-form-field" appearance="standard">
              <mat-label>Specify other reason</mat-label>
              <textarea matInput formControlName="otherReason" placeholder="Enter reason"></textarea>
            </mat-form-field>
          </div>

          <br>
          <button class="submit-button" mat-raised-button style="background-color: #ff403c; color: white;" type="submit"
            [disabled]="!eapForm.valid || !showCounter || (eapForm.get('isApproved').value === false && !eapForm.get('rejectReason').value)">
            Submit
          </button>

        </form>
      </div>
    </div>

  </article>
</div>