<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <article class="grid">

    <!-- <h2 class="mdc-typography--headline5 mb-3" style="margin-left: 8%;"> Assigned and Un-assigned events for
      <i>{{practioner.name}}</i>

    </h2>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="radioButtonValue" (change)="onRadioChange($event)"
      style="margin-left: 8%;">
      <mat-radio-button value="assign">Assign</mat-radio-button>
      <mat-radio-button value="unassign">Unassign</mat-radio-button>
    </mat-radio-group>
    <br><br> -->


    <!-- <div *ngIf="isAssignedEvents" class="main">
      <div class="mat-elevation-z8 consent-table" style="width: 60%; margin-left: 7%;">
        <table mat-table [dataSource]="assignedEvents" class="w-full">

          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 50%;"> Events </th>
            <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventName}} </td>
          </ng-container>

          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 30%;"> Event Date </th>
            <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventDate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="assignEventsColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: assignEventsColumn;"></tr>
        </table>

        <mat-paginator #assignedPaginator [pageSizeOptions]="[5, 10, 20, assignedEventsPageSize]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div> -->
    <!-- <div *ngIf="!isAssignedEvents" class="main">
      <div class="mat-elevation-z8 consent-table" style="width: 60%; margin-left: 7%;">
        <table mat-table [dataSource]="unassignedEvents" class="w-full">

          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 50%;"> Events </th>
            <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventName}} </td>
          </ng-container>

          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 30%;"> Event Date </th>
            <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventDate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="assignEventsColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: assignEventsColumn;"></tr>
        </table>

        <mat-paginator #unassignedPaginator [pageSizeOptions]="[5, 10, 20, unasssignedEventsPageSize]"
          showFirstLastButtons>
        </mat-paginator>
      </div>
    </div> -->
    <!-- <br><br> -->
    <h2 class="mdc-typography--headline5 mb-3" style="margin-left: 8%;">Select to assign events to {{practionerName}}
    </h2>
    <div class="mat-elevation-z8 consent-table" style="width: 80%; margin-left: 7%;">
      <table mat-table [dataSource]="eventsData" class="w-full">
        <ng-container matColumnDef="Select">
          <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 15%;"> Select </th>
          <td mat-cell *matCellDef="let element" class="text-left">
            <mat-checkbox [checked]="element.isAssigned" (change)="onEventSelect(element.id, $event.checked)">
            </mat-checkbox>
          </td>

        </ng-container>


        <ng-container matColumnDef="Event">
          <th mat-header-cell *matHeaderCellDef class="text-left"> Events </th>
          <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventName}} </td>
        </ng-container>

        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef class="text-left" style="width: 20%;"> Event Date </th>
          <td mat-cell *matCellDef="let element" class="text-left"> {{element.eventDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="allServiceProviderEventsColumn"></tr>
        <tr mat-row *matRowDef="let row; columns: allServiceProviderEventsColumn;"></tr>
      </table>

      <mat-paginator #eventsPaginator [pageSizeOptions]="[5, 10, 20, serviceProviderEventsPageSize]"
        showFirstLastButtons>
      </mat-paginator>
    </div>

    <button
      [ngClass]="{ 'mdc-button--raised':  true, 'mdc-ripple-upgraded': showAssignButton, 'mdc-button__fill--red':  true, 'mdc-button': true, 'mat-raised-button': !true }"
      (click)="assignSelectedEvents(selectedEvents)" id="saveButton"
      style="vertical-align: middle; margin-top: 4%; margin-left: 8%;">Submit</button>
  </article>
</div>