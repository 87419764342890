<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <!-- <div style="position: absolute; left: 6%;" (click)="goToInvoiceLanding()">
      <a href="#" class="previous round"><i style="font-size:24px" class="fa">&#xf015;</i></a>

    </div> -->
    <div>
      <h1 class="mdc-typography--headline2 text--align-center">Invoicing for Non-members</h1>
    </div>
  </section><br><br>
  <article class="grid">
    <!-- <p>claim-by-company works!</p> -->
    <!--Events Name-->
    <div style="display: flex;">
      <div class="grid__cell--span-3-desktop grid__cell--span-all" style="width: 50%; margin-right: 5%;">
        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
          <mat-label>Select the Event or Company</mat-label>
          <mat-select [(ngModel)]="selectedCompanyNames" (selectionChange)="selectOption($event)" #EventSelect multiple>
            <!--<mat-option [value]="null">All Events</mat-option>-->
            <mat-option *ngFor="let event of listOfCompanyNames" [value]="event.id">{{event.description}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button class="claimProcess" (click)="searchClaim()">Search</button>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="claimAllCompany" class="w-full">

        <!-- Position Column -->
        <ng-container matColumnDef="eventDate" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Event Date
          <th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.eventDate}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="companyName" width="40%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Event/Company Name </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.companyName}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="eventConsultationNum" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Number of members to claim </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.eventConsultationNum}} </td>
        </ng-container>

        <!-- Button Column -->
        <ng-container matColumnDef="action" width="10%">
          <th class="text-left" mat-header-cell *matHeaderCellDef></th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <button mat-raised-button style="background-color: #ff403c; color: white;"
              (click)="goToViewMembers(element)">View</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, pagesize]" showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>

  </article>

</div>